import { firestore } from '@/firebase'

const state = {
  // 取得した全動画
  // { id: {}, ... }
  movies: {},
  // ピックアップ動画の候補
  // { id: {}, ... }
  pickupMovies: {},
  // 最新の動画
  // 存在しない場合は none: {} を格納
  // { id: {}, ... }
  updatedMovies: {},
  // 職業タグが付与された最新の動画一覧
  // 存在しない場合は none: {} を格納
  // { N5: { id: {}, ... }, N4: { none: {} }, ... }
  newOccupationMovies: {
    N5: {}, N4: {}, N3: {}, N2: {}
  },
  // 職業タグが付与された全動画一覧
  occupationMovies: {
    N5: {}, N4: {}, N3: {}, N2: {}
  },
  // トピックタグが付与された最新の動画一覧
  // 存在しない場合は none: {} を格納
  // { N5: { id: {}, ... }, N4: { none: {} }, ... }
  newTopicMovies: {
    N5: {}, N4: {}, N3: {}, N2: {}
  },
  // トピックタグが付与された全動画一覧
  topicMovies: {
    N5: {}, N4: {}, N3: {}, N2: {}
  },
  // お気に入り動画一覧
  favMovies: []
}

const getters = {
  /**
   * 指定されたドキュメントIDの動画を取得
   * @param {String} id ドキュメントID
   * @return {Obeject} 指定されたドキュメントIDの動画
   */
  getMovie: state => id =>
    state.movies[id],
  /**
   * ピックアップ動画（最大5件）を取得
   * @return {Obeject} ピックアップ動画（最大5件
   */
  getPickupMovies: state => () =>
    state.pickupMovies,
  /**
     * 再生中の動画（最大5件）を取得
     * @return {Obeject} 再生中の動画（最大5件）
     */
  getWatchingMovies: state => () =>
    state.watchingMovies,
  /**
     * 最新の動画を取得
     * @return {Obeject} 最新の動画
     */
  getUpdatedMovies: state => () =>
    state.updatedMovies,
  /**
   * 指定された日本語レベルの職業タグが付与された最新の動画一覧を取得
   * @param {String} level 日本語レベル
   * @return {Object} 指定された日本語レベルの職業タグが付与された最新の動画一覧
   */
  getNewOccupationMovies: state => level =>
    state.newOccupationMovies[level],
  /**
   * 指定された日本語レベルのトピックタグが付与された最新の動画一覧を取得
   * @param {String} level 日本語レベル
   * @return {Object} 指定された日本語レベルのトピックタグが付与された最新の動画一覧
   */
  getNewTopicMovies: state => level =>
    state.newTopicMovies[level],
  /**
   * お気に入りの動画一覧を取得
   * @return {Object} お気に入りの動画一覧
   */
  getFavMovies: state => () =>
    state.favMovies
}

const actions = {
  /**
   * ピックアップ動画の候補を取得
   * @param {Object} payload { level: 日本語レベル, occupation: 職業名 }
   */
  addPickupMovies ({ commit }, payload) {
    return new Promise(resolve => {
      // 動画を全件取得
      firestore.collection('movie')
        .where('isDeleted', '==', false)
        .get()
        .then(snapshot => {
          // 全動画、日本語レベルのみ、日本語レベルかつ職業
          const all = {}
          const onlyLevles = {}
          const levelsAndOccupations = {}
          snapshot.forEach(doc => {
            all[doc.id] = doc.data()
            if (doc.data().levels.includes(payload.level)) onlyLevles[doc.id] = doc.data()
            if (doc.data().levels.includes(payload.level) && doc.data().occupation === payload.occupation) levelsAndOccupations[doc.id] = doc.data()
          })

          // 全動画をセット
          commit('setAllMovies', all)

          // 取得結果に応じてピックアップ動画の候補を選択
          const targets = Object.keys(levelsAndOccupations).length > 0 ? levelsAndOccupations
            : Object.keys(onlyLevles).length > 0 ? onlyLevles
              : all

          commit('setPickupMovies', targets)
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 再生中の動画を最大5件取得
   * 直近1カ月間にアップロードされた動画から最新の5件
   */
  addWatchingMovies ({ commit }, mids) {
    return new Promise(resolve => {
      const promises = []
      mids.forEach(mid => { promises.push(firestore.collection('movie').doc(mid).get()) })

      Promise.all(promises).then(responses => {
        const watchingMovies = {}
        responses.forEach(response => {
          if (!response.data().isDeleted) {
            watchingMovies[response.id] = response.data()
            commit('setMovie', { mid: response.id, data: response.data() })
          }
        })
        return resolve(watchingMovies)
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * 最新の動画を最大5件取得
   * 直近1カ月間にアップロードされた動画から最新の5件
   */
  addUpdatedMovies ({ commit }) {
    return new Promise(resolve => {
      const preDate = new Date()
      preDate.setMonth(preDate.getMonth() - 1)

      firestore.collection('movie')
        .where('isDeleted', '==', false)
        .where('createdAt', '>=', preDate)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .get()
        .then(snapshot => {
          commit('setUpdatedMovies', snapshot)
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 最新の職業タグが付与された動画を5件取得
   * @param {String} level 日本語レベル
   */
  addNewOccupationMovies ({ commit }, level) {
    return new Promise(resolve => {
      firestore.collection('movie')
        .where('levels', 'array-contains', level)
        .where('isDeleted', '==', false)
        .where('withOccupationTags', '==', true)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .get()
        .then(snapshot => {
          commit('setNewOccupationMovies', { level: level, snapshot: snapshot })
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 最新のトピックタグが付与された動画を5件取得
   * @param {String} level 日本語レベル
   */
  addNewTopicMovies ({ commit }, level) {
    return new Promise(resolve => {
      firestore.collection('movie')
        .where('levels', 'array-contains', level)
        .where('isDeleted', '==', false)
        .where('withTopicTags', '==', true)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .get()
        .then(snapshot => {
          commit('setNewTopicMovies', { level: level, snapshot: snapshot })
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 検索タグ（日本語レベル、職業、トピック）に基づき関連動画を取得し5件を返却
   * アルゴリズム：
   *  - 検索タグごとに、それぞれ最新を5件ずつ取得
   *  - 取得した動画を作成日順にソートし、最新から5件を返却
   *  - 例）日本語レベル2つ、職業タグ1つ、トピックタグ2つの場合、最大5*5=25件から作成日の最新5件を返却
   * @param {Object} payload { mid: 表示中の動画のドキュメントID, levels: 日本語レベルの配列, occupations: 職業タグの配列, topics: トピックタグの配列 }
   */
  addRelatedMovies ({ commit }, payload) {
    return new Promise(resolve => {
      const promises = []

      // 指定された日本語レベルの動画の取得
      payload.levels.forEach(level => {
        promises.push(
          firestore.collection('movie')
            .where('levels', 'array-contains', level)
            .where('isDeleted', '==', false)
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get()
        )
      })
      // 指定された職業タグの動画の取得
      payload.occupations.forEach(occupation => {
        promises.push(
          firestore.collection('movie')
            .where('occupations', 'array-contains', occupation)
            .where('isDeleted', '==', false)
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get()
        )
      })
      // 指定されたトピックタグの動画の取得
      payload.topics.forEach(topic => {
        promises.push(
          firestore.collection('movie')
            .where('topics', 'array-contains', topic)
            .where('isDeleted', '==', false)
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get()
        )
      })

      Promise.all(promises).then(responses => {
        const movies = []
        const ids = []
        responses.forEach(response => {
          response.forEach(doc => {
            if (movies.length === 0 && doc.id !== payload.mid) {
              // 表示中の動画じゃなければ、最初は普通に挿入
              movies.push(Object.assign({ id: doc.id }, doc.data()))
            } else if (!ids.includes(doc.id) && doc.id !== payload.mid) {
              // 既に挿入済みじゃない、かつ表示中の動画でもない場合は、作成日順にソートしながら挿入
              movies[movies.length - 1].createdAt > doc.data().createdAt
                ? movies.push(Object.assign({ id: doc.id }, doc.data())) : movies.unshift(Object.assign({ id: doc.id }, doc.data()))
            }
            ids.push(doc.id)
            commit('setMovie', { mid: doc.id, data: doc.data() })
          })
        })

        return resolve(movies.slice(0, 5))
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * @param {Object} payload { mids: 取得する動画一覧, isFirst: 最初かどうか }
   */
  addFavMovies ({ commit, getters }, payload) {
    return new Promise(resolve => {
      // 初期化処理
      if (payload.isFirst) commit('resetFavMovies')

      // 既に動画がある場合は取得しない
      const promises = []
      payload.mids.forEach(mid => {
        if (getters.getMovie(mid) === undefined) promises.push(firestore.collection('movie').doc(mid).get())
      })

      Promise.all(promises).then(responses => {
        const temps = {}
        responses.forEach(doc => {
          temps[doc.id] = doc.data()
        })

        // 今回取得分の既に取得分とをマージ
        const results = []
        payload.mids.forEach(mid => {
          if (temps[mid] !== undefined) {
            if (!temps[mid].isDeleted) results.push(Object.assign({ id: mid }, temps[mid]))
          } else {
            const movie = getters.getMovie(mid)
            if (!movie.isDeleted) results.push(Object.assign({ id: mid }, movie))
          }
        })

        commit('setFavMovies', results)
        return resolve()
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * 動画の検索候補を取得
   * firestoreの関係上、完全一致で検索するのは難しいので、ここでは候補を返却する
   * @param {Object} payload { levels: 検索対象の日本語レベルの配列, topics: 検索対象のトピックの配列, occupations: 検索対象の職業の配列 }
   */
  searchMovies ({ commit }, payload) {
    return new Promise(resolve => {
      let query
      if (payload.levels.length === 0 && payload.topics.length === 0 && payload.occupations.length === 0) {
        query = firestore.collection('movie')
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length > 0 && payload.topics.length > 0 && payload.occupations.length > 0) {
        query = firestore.collection('movie')
          .where('levels', 'array-contains', payload.levels[0])
          .where('withTopicTags', '==', true)
          .where('withOccupationTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length === 0 && payload.topics.length > 0 && payload.occupations.length > 0) {
        query = firestore.collection('movie')
          .where('withTopicTags', '==', true)
          .where('withOccupationTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length > 0 && payload.topics.length === 0 && payload.occupations.length > 0) {
        query = firestore.collection('movie')
          .where('levels', 'array-contains', payload.levels[0])
          .where('withOccupationTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length > 0 && payload.topics.length > 0 && payload.occupations.length === 0) {
        query = firestore.collection('movie')
          .where('levels', 'array-contains', payload.levels[0])
          .where('withTopicTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length === 0 && payload.topics.length === 0 && payload.occupations.length > 0) {
        query = firestore.collection('movie')
          .where('withOccupationTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length === 0 && payload.topics.length > 0 && payload.occupations.length === 0) {
        query = firestore.collection('movie')
          .where('withTopicTags', '==', true)
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      } else if (payload.levels.length > 0 && payload.topics.length === 0 && payload.occupations.length === 0) {
        query = firestore.collection('movie')
          .where('levels', 'array-contains', payload.levels[0])
          .where('isDeleted', '==', false)
          .orderBy('createdAt', 'desc')
          .get()
      }
      query.then(snapshot => {
        const results = []
        snapshot.forEach(doc => {
          commit('setMovie', { mid: doc.id, data: doc.data() })

          const levelCondition = payload.levels.filter(level => doc.data().levels.includes(level)).length === payload.levels.length
          const topicCondition = payload.topics.includes('all') ? true : payload.topics.filter(topic => doc.data().topics.includes(topic)).length === payload.topics.length
          const occupationCondition = payload.occupations.includes('all') ? true : payload.occupations.filter(occupation => doc.data().occupations.includes(occupation)).length === payload.occupations.length

          if (levelCondition && topicCondition && occupationCondition) {
            results.push(Object.assign({ id: doc.id }, doc.data()))
          }
        })
        return resolve(results)
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  }
}

const mutations = {
  /**
   * 取得した動画をstateにセット
   * @param {Object} payload { mid: 動画ドキュメントID, data: 動画オブジェクト }
   */
  setMovie: (state, payload) => {
    state.movies[payload.mid] = payload.data
  },
  /**
   * 全動画一覧をstateにセット
   * @param {Object} movies 全動画一覧
   */
  setAllMovies: (state, movies) => {
    state.movies = movies
  },
  /**
   * 取得したお気に入り動画をstateにセット
   * @param {Array} movies 動画オブジェクトの配列
   */
  setFavMovies: (state, movies) => {
    movies.forEach(movie => {
      state.favMovies.push(movie)
      state.movies[movie.id] = movie
    })
  },
  /**
   * お気に入り情報をリセット
   */
  resetFavMovies: (state) => {
    state.favMovies = []
  },
  /**
   * ピックアップ動画をstateにセット
   * @param {Object} obj 結果
   */
  setPickupMovies: (state, obj) => {
    state.pickupMovies = obj
    Object.keys(obj).forEach(id => {
      state.movies[id] = obj[id]
    })
  },
  /**
   * 最新の動画をstateにセット
   * @param {Object} snapshot 取得結果
   */
  setUpdatedMovies: (state, snapshot) => {
    snapshot.forEach(doc => {
      state.updatedMovies[doc.id] = doc.data()
      state.movies[doc.id] = doc.data()
    })
  },
  /**
   * 最新の職業タグが付与された動画をstateにセット
   * @param {Object} payload { level: 日本語レベル, snapshot: 取得結果 }
   */
  setNewOccupationMovies: (state, payload) => {
    payload.snapshot.forEach(doc => {
      state.newOccupationMovies[payload.level][doc.id] = doc.data()
      state.occupationMovies[payload.level][doc.id] = doc.data()
      state.movies[doc.id] = doc.data()
    })
  },
  /**
   * 最新の職業タグが付与された動画をstateにセット
   * @param {Object} payload { level: 日本語レベル, snapshot: 取得結果 }
   */
  setNewTopicMovies: (state, payload) => {
    payload.snapshot.forEach(doc => {
      state.newTopicMovies[payload.level][doc.id] = doc.data()
      state.topicMovies[payload.level][doc.id] = doc.data()
      state.movies[doc.id] = doc.data()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
