import { firestore } from '@/firebase'

const state = {
  // お知らせ情報
  notices: [],
  // 未読お知らせのID一覧
  unreadNoticeIDs: []
}

const getters = {
  /**
   * @return {Array} 全お知らせ情報
   */
  getNotices: state => () =>
    state.notices,
  /**
   * @return {Array} 全未読お知らせ情報
   */
  getUnreadNoticeIDs: state => () =>
    state.unreadNoticeIDs
}

const actions = {
  /**
   * 取得済みのお知らせより古いお知らせを12件分取得し格納する
   * @param {String} uid ユーザーID
   * @return {Promise}
   */
  addNotices ({ commit, getters }, uid) {
    return new Promise(resolve => {
      const notices = getters.getNotices()
      // 未取得の場合は、今より古いものを取得
      // 取得済みの場合は、取得済みのものより古いものを取得
      const at = notices.length === 0 ? new Date() : notices[notices.length - 1].createdAt
      firestore
        .collection('notice')
        .where('createdAt', '<', at)
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc')
        .limit(12)
        .get()
        .then(snapshot => {
          commit('setNotices', { uid: uid, snapshot: snapshot })
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * お知らせ情報を編集
   * @param {Object} payload { id: ドキュメントID, params: 変更するパラメータ }
   */
  updateNotice ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('notice')
        .doc(payload.id)
        .update(payload.params)
        .then(() => {
          // 未読情報の更新の場合
          if (payload.params.readed !== undefined) {
            commit('updateUnreadInfo', payload.id)
          }
          commit('updateNotice', { id: payload.id, params: payload.params })
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

const mutations = {
  /**
   * 取得したお知らせ情報をstateにセットするための関数
   * @param {Object} data { uid: ユーザーID, snapshot : 取得したお知らせ情報のスナップショット }
   */
  setNotices: (state, data) => {
    data.snapshot.forEach(doc => {
      state.notices.push(Object.assign(doc.data(), { id: doc.id }))
      if (!doc.data().readed.includes(data.uid)) state.unreadNoticeIDs.push(doc.id)
    })
  },
  /**
   * お知らせ情報の更新
   * @param {Object} payload { id: 更新するお知らせのドキュメントID, params: 更新する情報のオブジェクト }
   */
  updateNotice: (state, payload) => {
    for (let n = 0; n < state.notices.length; n++) {
      if (state.notices[n].id === payload.id) {
        Object.keys(payload.params).forEach(key => {
          state.notices[n][key] = payload.params[key]
        })
      }
    }
  },
  /**
   * 未読情報の更新
   * @param {String} id 既読にするお知らせのドキュメントID
   */
  updateUnreadInfo: (state, id) => {
    state.unreadNoticeIDs = state.unreadNoticeIDs.filter(noticeID => noticeID !== id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
