import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 職業情報 { id: 職業のオブジェクト, ... }
  occupations: {}
}

const getters = {
  /**
   * @return {Object} 職業情報一覧
   */
  occupations: state => state.occupations,
  /**
   * @param {String} oid 職業ID
   * @return {Object} 職業情報
   */
  occupation: state => oid => state.occupations[oid] ? state.occupations[oid] : {}
}

const actions = {
  /**
   * 職業情報を取得
   * @param {String} oid 職業ID
   */
  getOccupation ({ commit }, oid) {
    return new Promise(resolve => {
      firestore
        .collection('occupation')
        .doc(oid)
        .get()
        .then(doc => {
          commit('setOccupation', doc)
          return resolve(doc.data().name)
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * 職業情報一覧を取得
   */
  getOccupations ({ commit }) {
    return new Promise(resolve => {
      firestore
        .collection('occupation')
        .where('isDeleted', '==', false)
        .get()
        .then(snapshot => {
          commit('setOccupations', snapshot)
          return resolve()
        })
        .catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
  /**
   * 職業情報のセット
   * @param {Object} doc 職業情報のドキュメント
   */
  setOccupation: (state, doc) => {
    state.occupations[doc.id] = doc.data()
  },
  /**
   * 職業情報のセット
   * @param {Object} snapshot 職業情報のドキュメント一覧
   */
  setOccupations: (state, snapshot) => {
    snapshot.forEach(doc => {
      state.occupations[doc.id] = doc.data()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
