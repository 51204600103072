import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: loadComponent('Home'),
      meta: { requiresAuth: true }
    },
    {
      path: '/favorite',
      name: 'Favorite',
      component: loadComponent('Favorite'),
      meta: { requiresAuth: true }
    },
    {
      path: '/study',
      name: 'Study',
      component: loadComponent('Study'),
      meta: { requiresAuth: true }
    },
    {
      path: '/study/vocabulary',
      name: 'StudyVocabulary',
      component: loadComponent('StudyVocabulary'),
      meta: { requiresAuth: true }
    },
    {
      path: '/study/vocabulary/test',
      name: 'StudyVocabularyTest',
      component: loadComponent('StudyVocabularyTest'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exam',
      name: 'Exam',
      component: loadComponent('Exam'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exam/:eid',
      name: 'ExamQuestion',
      component: loadComponent('ExamQuestion'),
      meta: { requiresAuth: true }
    },
    {
      path: '/exam/:eid/result',
      name: 'ExamResult',
      component: loadComponent('ExamResult'),
      meta: { requiresAuth: true }
    },
    {
      path: '/video',
      name: 'Video',
      component: loadComponent('Video'),
      meta: { requiresAuth: true }
    },
    {
      path: '/video/:id',
      name: 'VideoDetail',
      component: loadComponent('VideoDetail'),
      meta: { requiresAuth: true }
    },
    {
      path: '/search',
      name: 'Search',
      component: loadComponent('Search'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit',
      name: 'ProfileEdit',
      component: loadComponent('ProfileEdit'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'Menu',
      component: loadComponent('Menu'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/notificaiton',
      name: 'MenuNotification',
      component: loadComponent('MenuNotification'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/cancel',
      name: 'MenuCancel',
      component: loadComponent('MenuCancel'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu/cancel/reason',
      name: 'MenuCancelReason',
      component: loadComponent('MenuCancelReason'),
      meta: { requiresAuth: true }
    },
    {
      path: '/lp',
      name: 'LP',
      component: loadComponent('LP')
    },
    {
      path: '/deleted',
      name: 'Deleted',
      component: loadComponent('Deleted')
    },
    {
      path: '/login',
      name: 'Login',
      component: loadComponent('Login')
    },
    {
      path: '/signup',
      name: 'Signup',
      component: loadComponent('Signup'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'Error',
      component: loadComponent('Error'),
      meta: { autoTransition: true }
    },
    {
      path: '/double',
      name: 'Double',
      component: loadComponent('Double'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'NotFound',
      component: loadComponent('NotFound'),
      meta: { autoTransition: true }
    },
    {
      path: '*',
      redirect: { name: 'NotFound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

/**
 * コンポーネントのロード
 * @param {String} name コンポーネントのパス
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ログインの有無による遷移先の処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  )
  const isAuthProcessing = store.getters.isAuthProcessing

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const user = store.getters['user/user']

  if (!isSP) { // PCからのアクセスの場合問答無用でLPに遷移させる
    to.name === 'LP' ? next() : next({ name: 'LP' })
  } else if (isAuthProcessing || autoTransition) {
    // 初回アクセス時 または 無条件で遷移されるページの場合
    next()
  } else if (auth) {
    if (user) {
      // ログイン済みかつuser情報がある場合
      requiresAuth ? next() : next({ name: 'Home' })
    } else {
      // ログイン済みかつuser情報がない場合
      to.name === 'Signup' ? next() : next({ name: 'Signup' })
    }
  } else {
    // 未ログインの場合
    if (requiresAuth) {
      next({ name: 'Login' })
    } else {
      to.name === 'LP' ? next({ name: 'Login' }) : next()
    }
  }
})

export default router
