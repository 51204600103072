import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const actions = {
  /**
   * 指定したPINの履歴を取得
   * @param {String} pin ピンコード
   */
  getPINHistory ({ commit }, pin) {
    return new Promise(resolve => {
      firestore.collection('pin_history')
        .where('pin', '==', pin)
        .get()
        .then(snapshot => {
          return resolve(snapshot)
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * PINの履歴を作成
   * @param {Object} payload { uid: ユーザーID, pin: ピンコード, customer: 卸先名, status: ステータス, plan: 契約プラン数, startAt: 契約開始日, endAt: 契約終了日 }
   */
  createPINHistory ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('pin_history')
        .add({
          uid: payload.uid,
          pin: payload.pin,
          customer: payload.customer,
          status: payload.status,
          plan: payload.plan,
          startAt: payload.startAt,
          endAt: payload.endAt
        }).then(() => {
          return resolve()
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
