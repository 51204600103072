import { firestore } from '@/firebase'

const state = {
  // お気に入り情報
  favs: []
}

const getters = {
  /**
   * @return {Object} 全お気に入り情報
   */
  getMovieAllFavs: state => () =>
    state.favs
}

const actions = {
  /**
   * @param {String} uid ユーザーID
   * @return {Promise}
   */
  addMovieFavs ({ commit }, uid) {
    return new Promise(resolve => {
      firestore
        .collection('fav_movie')
        .where('uid', '==', uid)
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshot => {
          commit('setMovieFavs', snapshot)
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * @param {String} payload { uid: ユーザーID, mid: 動画のドキュメントID }
   * @return {Promise}
   */
  addMovieFav ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('fav_movie')
        .where('uid', '==', payload.uid)
        .where('mid', '==', payload.mid)
        .get()
        .then(snapshot => {
          let id = ''
          snapshot.forEach(doc => { id = doc.id })
          return resolve(id)
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * お気に入り登録 or 解除
   * @param {String} payload { id: 動画のお気に入りドキュメントID, uid: ユーザーID, mid: 動画のドキュメントID }
   */
  doMovieFav ({ commit }, payload) {
    return new Promise(resolve => {
      if (payload.id.length === 0) { // 登録
        firestore
          .collection('fav_movie')
          .add({
            uid: payload.uid,
            mid: payload.mid,
            createdAt: new Date()
          }).then(doc => {
            return resolve(doc.id)
          })
          .catch(() => {
            window.location.href = '/error'
          })
      } else { // 解除
        firestore
          .collection('fav_movie')
          .doc(payload.id)
          .delete()
          .then(() => {
            return resolve('')
          })
          .catch(() => {
            window.location.href = '/error'
          })
      }
    })
  }
}

const mutations = {
  /**
   * 取得した動画のお気に入り情報をstateにセットするための関数
   * @param {Object} snapshot 取得した動画情報のスナップショット
   */
  setMovieFavs: (state, snapshot) => {
    state.favs = []
    snapshot.forEach(doc => {
      state.favs.push(doc.data().mid)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
