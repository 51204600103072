import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  uid: '',
  user: null
}

const getters = {
  /**
   * @return {String} ユーザーID
   */
  uid: state => state.uid,
  /**
   * @return {Object} ユーザー情報
   */
  user: state => state.user
}

const actions = {
  /**
   * ユーザー情報の取得
   * @param {String} uid ユーザーID
   */
  getUser ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('user')
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            commit('setUID', doc.id)
            commit('setUser', doc.data())
            return resolve(doc.data())
          }
          return resolve()
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * ユーザー情報の作成
   * @param {*} payload { uid: ユーザーID, param: 登録情報のオブジェクト }
   */
  createUser ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('user').doc(payload.uid).set(payload.param).then(() => {
        commit('setUID', payload.uid)
        commit('setUser', payload.param)
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * ユーザー情報の更新
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.param 更新パラメータのオブジェクト
   * @param {Boolean} payload.edited 職業が更新されたかどうか
   */
  updateUser ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('user').doc(payload.uid).update(payload.param).then(() => {
        commit('renewUser', payload.param)
        if (payload.edited) {
          // 職業が更新された場合、試験とそれに係るstore情報を全てリセットする
          commit('exams/resetExams', null, { root: true })
          commit('questions/resetQuestions', null, { root: true })
          commit('answers/resetAnswers', null, { root: true })
          commit('results/resetResults', null, { root: true })
        }
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * ユーザーの削除
   * @param {String} uid ユーザーID
   */
  deleteUserInfo ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('user').doc(uid).delete().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  }
}

const mutations = {
  /**
   * ユーザーIDをstateにセット
   * @param {String} uid ユーザーIDをstateにセット
   */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * ユーザー情報のオブジェクトをstateにセット
   * @param {Object} user ユーザー情報のオブジェクト
   */
  setUser: (state, user) => {
    state.user = user
  },
  /**
   * ユーザー情報の更新結果をstateにセット
   * @param {Object} param 更新パラメータ
   */
  renewUser: (state, param) => {
    Object.keys(param).forEach(key => {
      state.user[key] = param[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
