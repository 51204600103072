import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 取得済み試験情報
  // { eid: {}, eid: {}, ... }
  exams: {}
}

const getters = {
  /**
   * @return {Object} 試験情報一覧
   */
  exams: state => () => Object.keys(state.exams).length ? state.exams : null,
  /**
   * @param {String} eid 試験ID
   * @return {Object} 試験情報
   */
  exam: state => eid => state.exams[eid] ? state.exams[eid] : null
}

const actions = {
  /**
   * 試験情報一覧の取得
   * @param {String} oid 取得する試験の職業ID
   */
  getExams ({ commit, getters }, oid) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .where('oid', '==', oid)
        .orderBy('times', 'asc')
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const exam = { eid: doc.id, exam: doc.data() }
            commit('setExam', exam)
          })
          return resolve(getters.exams())
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * 試験情報の取得
   * @param {String} eid 取得する試験ID
   */
  getExam ({ commit, getters }, eid) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(eid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const exam = { eid: doc.id, exam: doc.data() }
            commit('setExam', exam)
          }
          return resolve(getters.exam(eid))
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
  /**
   * 試験情報をstateにセット
   * @param {String} payload.eid 試験ID
   * @param {Object} payload.exam 試験情報
   */
  setExam: (state, payload) => {
    state.exams[payload.eid] = payload.exam
  },
  /**
   * 試験情報をリセット
   */
  resetExams: (state) => {
    state.exams = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
