<template>
  <transition name="fade">
    <div
      :class="telop.type === 'success' ? 'telop'
        : telop.type === 'warning' ? 'telop--yellow'
          : telop.type === 'error' ? 'telop--red' : ''">
      <img class="telop__icon" :src="telop.type === 'success' ? require('@/assets/img/success.svg') : require('@/assets/img/caution.svg')" />
      <p :class="includeNewline ? 'telop__text--multi' : 'telop__text'">{{ telop.msg }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  mounted () {
    // 3秒後にテロップを消す
    setTimeout(() => {
      this.$store.commit('setTelop', { show: false, msg: '', type: '' })
    }, 3000)
  },
  computed: {
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    },
    /**
     * @return {Boolean} 改行コードが含まれているかどうか
     */
    includeNewline () {
      return this.telop.msg.indexOf('\n') !== -1
    }
  }
}
</script>

<style scoped lang="scss">

.telop {
  position: fixed;
  top: 0;
  left: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  text-align: center;
  background-color: #1cc2a6;
  transform: translate(-50%, 0);
  height: 74px;
  width: 100vw;
  z-index: 1000;
  &--yellow {
    @extend .telop;
    background-color: #dec545;
  }
  &--red {
    @extend .telop;
    background-color: #f57373;
  }
  &__icon {
    height: 23px;
    width: 23px;
  }
  &__text {
    max-width: 75%;
    margin: 0;
    margin-left: 6px;
    font-size: 16px;
    color: white;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    &--multi {
      @extend .telop__text;
      font-size: 14px;
    }
  }
}

// アニメーション用
.fade-enter-active,
.fade-leave-active {
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  will-change: opacity;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
