import { firestore } from '@/firebase'

const state = {
  // 取得した単語帳をレベルごとに格納するためのもの
  favs: {
    N5: { 名詞: '', 動詞: '', 形容詞: '' },
    N4: { 名詞: '', 動詞: '', 形容詞: '' },
    N3: { 名詞: '', 動詞: '', 形容詞: '' },
    N2: { 名詞: '', 動詞: '', 形容詞: '' }
  },
  // 取得したかどうか
  isAcquired: false
}

const getters = {
  /**
   * @return {Boolean} お気に入り情報を取得したかどうか
   */
  getIsAcquired: state => () =>
    state.isAcquired,
  /**
   * @return {Object} 全お気に入り情報
   */
  getVocabularyAllFavs: state => () =>
    state.favs,
  /**
   * 単語帳のお気に入り情報を取得
   * @param {String} level N5 - N2のいずれか
   * @return {Object} お気に入り情報
   */
  getVocabularyFavs: state => level =>
    state.favs[level]
}

const actions = {
  /**
   * @param {String} uid ユーザーID
   * @return {Promise}
   */
  addVocabularyFavs ({ commit }, uid) {
    return new Promise(resolve => {
      firestore
        .collection('fav_vocabulary')
        .where('uid', '==', uid)
        .get()
        .then(snapshot => {
          if (snapshot.size !== 0) {
            commit('setVocaburaryFavs', snapshot)
          }
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * お気に入り登録 or 解除
   * @param {String} payload id（お気に入り情報のID）、uid、level（日本語レベル）, genre（単語の種類）
   */
  doVocabularyFav ({ commit }, payload) {
    return new Promise(resolve => {
      if (payload.id.length === 0) { // 登録
        firestore
          .collection('fav_vocabulary')
          .add({
            uid: payload.uid,
            level: payload.level,
            genre: payload.genre,
            createdAt: new Date()
          }).then(doc => {
            commit('changeVocaburaryFav', {
              id: doc.id,
              level: payload.level,
              genre: payload.genre
            })
            return resolve()
          })
          .catch(() => {
            window.location.href = '/error'
          })
      } else { // 解除
        firestore
          .collection('fav_vocabulary')
          .doc(payload.id)
          .delete()
          .then(() => {
            commit('changeVocaburaryFav', {
              id: '',
              level: payload.level,
              genre: payload.genre
            })
            return resolve()
          })
          .catch(() => {
            window.location.href = '/error'
          })
      }
    })
  }
}

const mutations = {
  /**
   * 取得した単語帳のお気に入り情報をstateにセットするための関数
   * @param {Object} snapshot 取得したお気に入り情報のスナップショット
   */
  setVocaburaryFavs: (state, snapshot) => {
    snapshot.forEach(doc => {
      state.favs[doc.data().level][doc.data().genre] = doc.id
    })
    state.isAcquired = true
  },
  /**
   * 単語帳のお気に入り情報の更新
   * @param {Object} payload id（お気に入り情報のID）、level（日本語レベル）、genre（ジャンル）
   */
  changeVocaburaryFav: (state, payload) => {
    state.favs[payload.level][payload.genre] = payload.id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
