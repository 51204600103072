import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  pin: '',
  info: {},
  rest: 0
}

const getters = {
  /**
   * 登録予定のPINを取得
   * @return {String} PIN
   */
  getPIN: state => () =>
    state.pin,
  /**
   * @return {Object} PIN情報
   */
  getPINInfo: state => () =>
    state.info,
  /**
   * @return {Number} 残り契約日数
   */
  getRest: state => () =>
    state.rest
}

const actions = {
  /**
   * PINの取得
   * @param {String} pin ピンコード
   * @return {Promise} ピンが存在する場合：PINのオブジェクト、しない場合：空オブジェクト
   */
  getPIN ({ commit }, pin) {
    return new Promise(resolve => {
      firestore.collection('pin')
        .doc(pin)
        .get()
        .then(doc => {
          return resolve(doc.exists ? doc.data() : {})
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * 指定UIDが登録されているPINの取得
   * @param {String} uid ユーザーID
   * @return {Promise} ピンが存在する場合：PINのオブジェクト、しない場合：空オブジェクト
   */
  getPINByUID ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('pin')
        .where('uid', '==', uid)
        .get()
        .then(snapshot => {
          // ピンが存在する場合
          if (snapshot.size === 1) {
            let pin = {}
            snapshot.forEach(doc => { pin = { id: doc.id, obj: doc.data() } })
            commit('setPIN', pin.id)
            commit('setInfo', pin.obj)
            commit('setRest', pin.obj.endAt)
            return resolve(pin.obj)
          }
          return resolve({})
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * PINの登録
   * @param {Object} payload { pin: ピンコード, uid: ユーザーID, startAt: 契約開始日, endAt: 契約終了日 }
   */
  registratePIN ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('pin').doc(payload.pin).update({
        uid: payload.uid,
        startAt: payload.startAt,
        endAt: payload.endAt,
        updatedAt: payload.updatedAt
      }).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * PINの修正
   * @param {Object} payload { pin: PINコード, paramas: 更新パラメータ }
   */
  updatePIN ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('pin').doc(payload.pin).update(payload.params).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  }
}

const mutations = {
  /**
   * PINコードをstateにセット
   * @param {String} pin PINコード
   */
  setPIN: (state, pin) => {
    state.pin = pin
  },
  /**
   * PIN情報のオブジェクトをstateにセット
   * @param {Object} info PIN情報のオブジェクト
   */
  setInfo: (state, info) => {
    state.info = info
  },
  /**
   * PINの残り契約日数をstateにセット
   * @param {Date} endAt 契約終了日に日付
   */
  setRest: (state, endAt) => {
    state.rest = Math.ceil((endAt.toDate() - new Date()) / 86400000)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
