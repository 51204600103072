import { firestore } from '@/firebase'

const state = {
  // トピック情報 { id: トピックのオブジェクト, ... }
  topics: {}
}

const getters = {
  /**
   * @param {String} id ドキュメントID
   * @return {Object} トピック情報
   */
  getTopic: state => id =>
    state.topics[id],
  /**
   * @return {Object} トピック情報一覧
   */
  topics: state => state.topics
}

const actions = {
  /**
   * トピック情報の一覧を取得
   */
  getTopics ({ commit }) {
    return new Promise(resolve => {
      firestore
        .collection('topic')
        .where('isDeleted', '==', false)
        .get()
        .then(snapshot => {
          commit('setTopics', snapshot)
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

const mutations = {
  /**
   * トピック情報のセット
   * @param {Object} snapshot トピック情報のドキュメント一覧
   */
  setTopics: (state, snapshot) => {
    snapshot.forEach(doc => {
      state.topics[doc.id] = doc.data()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
