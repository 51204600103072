import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 取得済み試験結果情報
  // { eid: {}, eid: {}, ... }
  results: {}
}

const getters = {
  /**
   * @return {Object} ユーザーに紐付く試験結果情報一覧
   */
  results: state => () => Object.keys(state.results).length ? state.results : null,
  /**
   * @param {String} eid 試験ID
   * @return {Object} 試験結果情報
   */
  result: state => eid => state.results[eid] ? state.results[eid] : null
}

const actions = {
  /**
   * 試験結果情報の取得
   * @param {String} payload.eid 取得する結果の試験ID
   * @param {String} payload.uid 取得する結果のユーザーID
   */
  getResult ({ commit, getters }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(payload.eid)
        .collection('results')
        .doc(payload.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const result = { eid: payload.eid, result: doc.data() }
            commit('setResult', result)
          }
          return resolve(getters.result(payload.eid))
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * 試験結果情報の登録/更新
   * @param {String} payload.eid 登録する結果の試験ID
   * @param {String} payload.uid 登録する結果のユーザーID
   * @param {String} payload.params 登録する結果情報
   */
  setResult ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(payload.eid)
        .collection('results')
        .doc(payload.uid)
        .set(payload.params, { merge: true })
        .then(() => {
          const result = { eid: payload.eid, result: payload.params }
          commit('setResult', result)
          return resolve()
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
  /**
   * 試験結果情報をstateにセット
   * @param {String} payload.eid 試験ID
   * @param {Object} payload.result 試験結果情報
   */
  setResult: (state, payload) => {
    state.results[payload.eid] = payload.result
  },
  /**
   * 試験結果情報をstateにセット
   */
  resetResults: (state) => {
    state.results = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
