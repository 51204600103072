import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 取得済み試験解答情報
  // { eid: { qid: {}, qid: {}, ...}, eid: { qid: {}, qid: {}, ...}, ...}
  answers: {}
}

const getters = {
  /**
   * @param {String} eid 試験ID
   * @return {Object} 試験解答情報一覧
   */
  answers: state => eid => state.answers[eid] ? state.answers[eid] : null,
  /**
   * @param {String} eid 試験ID
   * @param {String} qid 試験問題ID
   * @return {Object} 試験解答情報
   */
  answer: state => (eid, qid) => !state.answers[eid] ? null
    : state.answers[eid][qid] ? state.answers[eid][qid] : null
}

const actions = {
  /**
   * 試験解答情報の取得
   * @param {String} payload.eid 取得する解答の試験ID
   * @param {String} payload.qid 取得する解答の試験問題ID
   * @param {String} payload.uid 取得する解答のユーザーID
   */
  getAnswer ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(payload.eid)
        .collection('questions')
        .doc(payload.qid)
        .collection('answers')
        .where('uid', '==', payload.uid)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(snapshot => {
          let answer = null
          snapshot.forEach(doc => {
            answer = { eid: payload.eid, qid: payload.qid, answer: doc.data() }
            commit('setAnswer', answer)
          })
          return resolve(answer)
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  },
  /**
   * 試験解答情報の追加
   * @param {String} payload.eid 追加する解答の試験ID
   * @param {String} payload.qid 追加する解答の試験問題ID
   * @param {String} payload.params 追加する解答情報
   */
  addAnswer ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(payload.eid)
        .collection('questions')
        .doc(payload.qid)
        .collection('answers')
        .add(payload.params)
        .then(() => {
          const answer = { eid: payload.eid, qid: payload.qid, answer: payload.params }
          commit('setAnswer', answer)
          return resolve()
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
  /**
   * 試験解答情報をstateにセット
   * @param {String} payload.eid 試験ID
   * @param {String} payload.qid 試験問題ID
   * @param {Object} payload.answer 試験解答情報
   */
  setAnswer: (state, payload) => {
    if (!state.answers[payload.eid]) state.answers[payload.eid] = {}
    state.answers[payload.eid][payload.qid] = payload.answer
  },
  /**
   * 試験解答情報をリセット
   */
  resetAnswers: () => {
    state.answers = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
