import { firestore } from '@/firebase'

const state = {
}

const getters = {
}

const actions = {
  /**
   * 閲覧中の履歴のIDを取得
   * @param {Object} payload { uid: ユーザーID, mid: 動画のID }
   */
  async getWatchingMovieID ({ commit }, payload) {
    const id = await new Promise(resolve => {
      firestore.collection('watching_movie')
        .where('uid', '==', payload.uid)
        .where('mid', '==', payload.mid)
        .get()
        .then(snapshot => {
          if (snapshot.size === 1) { // 存在する場合
            let id = ''
            snapshot.forEach(doc => { id = doc.id })
            return resolve(id)
          } else { // 存在しない場合
            return resolve(null)
          }
        })
    })
    return id
  },
  /**
   * 閲覧中の動画のID一覧
   * @param {String} uid ユーザーID
   */
  getWatchingMovieIDs ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('watching_movie')
        .where('uid', '==', uid)
        .orderBy('updatedAt', 'desc')
        .get()
        .then(snapshot => {
          const ids = []
          snapshot.forEach(doc => {
            const mid = doc.data().mid
            if (!ids.includes(mid)) ids.push(doc.data().mid)
          })
          return resolve(ids)
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 閲覧履歴の作成
   * @param {Object} payload { uid: ユーザーID, mid: 動画のID, createdAt: 作成日, updatedAt: 更新日 }
   */
  createWatchingMovie ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('watching_movie')
        .add(payload)
        .then(() => {
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 閲覧履歴の更新
   * @param {Object} payload { id: ドキュメントID,  updatedAt: 更新日 }
   */
  updateWatchingMovie ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('watching_movie')
        .doc(payload.id)
        .update({ updatedAt: new Date() })
        .then(() => {
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 閲覧履歴の削除
   * @param {String} id: ドキュメントID
   */
  deleteWatchingMovie ({ commit }, id) {
    return new Promise(resolve => {
      firestore.collection('watching_movie')
        .doc(id)
        .delete()
        .then(() => {
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
