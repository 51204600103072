import { firestore } from '@/firebase'

const state = {
  // 取得した単語帳をレベルごとに格納するためのもの
  vocabularies: {
    N5: { 名詞: [], 動詞: [], 形容詞: [] },
    N4: { 名詞: [], 動詞: [], 形容詞: [] },
    N3: { 名詞: [], 動詞: [], 形容詞: [] },
    N2: { 名詞: [], 動詞: [], 形容詞: [] }
  }
}

const getters = {
  /**
   * 単語帳のデータを取得
   * @param {String} level N5 - N2のいずれか
   * @return {Object} 単語帳のデータ
   */
  getVocabularies: state => level =>
    state.vocabularies[level]
}

const actions = {
  /**
   * @param {String} level 日本語レベル
   * @return {Promise}
   */
  addVocabularies ({ commit }, level) {
    return new Promise(resolve => {
      firestore
        .collection('vocabulary')
        .where('level', '==', level)
        .where('isDeleted', '==', false)
        .get()
        .then(snapshot => {
          commit('setVocaburaries', { level: level, snapshot: snapshot })
          return resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

const mutations = {
  /**
   * 取得した単語帳をstateにセットするための関数
   * @param {Object} payload 取得した単語の日本語レベルと取得したスナップショット
   *    - { level: String, snapshot: Object}
   */
  setVocaburaries: (state, payload) => {
    // 初期化
    Object.keys(state.vocabularies[payload.level]).map(genre => {
      state.vocabularies[payload.level][genre] = []
    })

    // 新しい値を代入
    payload.snapshot.forEach(doc => {
      state.vocabularies[payload.level][doc.data().genre].push(doc.data())
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
