import { firebase, provider } from '@/firebase'
import router from '@/router'
import { getDeviceID } from '@/lib/common'

const state = {
}

const getters = {
}

const actions = {
  /**
   * auth情報の更新があった際の処理
   */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    // ユーザー情報の取得
    firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('user/setUID', auth ? auth.uid : null, { root: true })

      // 条件に応じて分岐
      if (auth) {
        // フィンガープリント取得時の例外パラメータ
        const options = require('@/fingerPrintOption.json')

        // ユーザー情報、ピンコード、デバイスIDの取得
        const [user, pin, deviceID] = await Promise.all([
          dispatch('user/getUser', auth.uid, { root: true }),
          dispatch('pin/getPINByUID', auth.uid, { root: true }),
          getDeviceID(options)
        ])

        // DBに登録されているデバイスID
        const registeredID = user ? user.deviceID : null

        // 退会処理かどうかの情報取得
        const cancelUID = sessionStorage.getItem('cancelUID')

        if (cancelUID) {
          const cancelReason = sessionStorage.getItem('cancelReason')
          await dispatch('cancel', { uid: cancelUID, reason: cancelReason })
        } else if (user) {
          // ユーザー情報、PIN情報、デバイス情報に応じて分岐
          if (user.authority !== 'admin' && (Object.keys(pin).length === 0 || pin.endAt.toDate() < new Date() || !pin.isAvailable)) {
            // 契約期限切れ、もしくは利用禁止のチェック
            // auth情報を残さないために、ログアウトする
            await dispatch('logout')

            // エラーページに遷移
            commit('setRedirectURL', '/error', { root: true })
          } else if (user.authority !== 'admin' && registeredID && registeredID !== deviceID) {
            // 管理者権限以外の二重ログインを防止する
            // DBにdeviceIDが登録済みかつ端末のdeviceIDと値が不一致の場合二重ログインとみなす
            await dispatch('logout')

            // 二重ログインページに遷移させる
            commit('setRedirectURL', '/double', { root: true })
          } else {
            const promises = []

            // deviceID未登録の場合は登録する
            if (!registeredID) {
              promises.push(dispatch('user/updateUser', { uid: auth.uid, param: { deviceID: deviceID, updatedAt: new Date() } }, { root: true }))
            }

            // 初期処理
            await dispatch('initialize', auth.uid)
          }
        }
      }
      commit('setAuthProcessing', false, { root: true })

      const redirectPath = rootGetters.redirectPath
      router.replace({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook
   * @return {void}
   */
  signin ({ commit }, sns) {
    return new Promise(resolve => {
      localStorage.setItem('loginProvider', sns)
      firebase.auth().signInWithRedirect(provider[sns]).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * ログアウト
   */
  logout ({ commit }) {
    return new Promise(resolve => {
      firebase.auth().signOut().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * ログイン及びサインアップ後に行う初期処理
   * @param {String} uid ログイン及びサインアップしたユーザーのID
   * @return {void}
   */
  initialize ({ commit, dispatch }, uid) {
    // @todo ESLintの設定修正時に削除する
    // @see https://github.com/nukumo/hanabi-main/issues/33
    // eslint-disable-next-line
    return new Promise(async resolve => {
      const promises = []

      // 講師情報の取得
      promises.push(dispatch('teacher/getTeachers', null, { root: true }))
      // コンディション情報の取得
      promises.push(dispatch('condition/getNewCondition', uid, { root: true }))
      // お知らせ情報の取得
      promises.push(dispatch('notice/addNotices', uid, { root: true }))
      // お知らせ・フィードバックの最新の12件の取得
      await Promise.all(promises)
      // GPSの取得設定
      dispatch('gps/getLocation', uid, { root: true })

      return resolve()
    }).catch(() => {
      router.push({ name: 'Error' })
    })
  },
  /**
   * ユーザーの認証情報の削除
   */
  deleteUserAuth ({ commit }) {
    return new Promise((resolve) => {
      firebase.auth().currentUser.delete().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'Error' })
      })
    })
  },
  /**
   * 退会処理を行う
   * @param {Object} payload 退会情報
   * @param {String} payload.uid 退会するユーザーのUID
   * @param {String} payload.reason 退会理由
   * @return {void}
   */
  cancel ({ commit, getters, rootGetters, dispatch }, payload) {
    // @todo ESLintの設定修正時に削除する
    // @see https://github.com/nukumo/hanabi-main/issues/33
    // eslint-disable-next-line
    return new Promise(async resolve => {
      // 退会処理を行ったアカウントと再認証アカウントが同じかどうか
      const isSameAccount = payload.uid === rootGetters['user/uid']

      // 退会処理
      // 退会処理を行ったGoogleアカウントと異なるアカウントで再認証した場合は、ログアウト
      if (isSameAccount) {
        const promises = []
        const endAt = new Date()
        const user = rootGetters['user/user']
        const pin = rootGetters['pin/getPINInfo']()
        // ユーザー情報の削除
        promises.push(dispatch('user/deleteUserInfo', payload.uid, { root: true }))
        // PINコードの利用終了
        promises.push(dispatch('pin/updatePIN', {
          pin: user.pin,
          params: {
            endAt: endAt,
            isAvailable: false,
            updatedAt: endAt,
            cancel: payload.reason
          }
        }, { root: true }))
        // PIN履歴に利用終了を追加
        promises.push(dispatch('pinHistory/createPINHistory', {
          uid: payload.uid,
          pin: user.pin,
          customer: pin.customer,
          status: '利用終了',
          plan: 0,
          startAt: endAt,
          endAt: null
        }, { root: true }))

        // 実施
        await Promise.all(promises)

        // firestoreの情報を全て更新・削除してから認証情報を削除
        await dispatch('deleteUserAuth')

        commit('setRedirectURL', '/deleted', { root: true })
      } else {
        await dispatch('logout')
        const msg = 'Xin vui lòng đăng nhập tài khoản và thực hiện việc huỷ bỏ thành viên'
        commit('setTelop', { show: true, msg: msg, type: 'error' }, { root: true })
      }

      // セッション情報の削除
      sessionStorage.removeItem('cancelUID')
      sessionStorage.removeItem('cancelReason')

      return resolve()
    }).catch(() => {
      router.push({ name: 'error' })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
