<template>
  <!-- isProcessing及びisSubmittingによる制御は#12で対応 -->
  <v-app id="app" v-if="!isAuthProcessing">
    <hanabi-header></hanabi-header>
    <router-view></router-view>
    <hanabi-footer></hanabi-footer>
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import HanabiHeader from '@/components/common/header'
import HanabiFooter from '@/components/common/footer'
import Telop from '@/components/common/telop'

export default {
  components: { HanabiHeader, HanabiFooter, Telop },
  data () {
    return {
      // ユーザー情報の取得中
      gettingUserInfo: true,
      // ユーザーID
      uid: ''
    }
  },
  mounted () {
    // 認証後の遷移先として現在のURLを保存する
    this.$store.commit('setRedirectURL', window.location.pathname)

    // authの監視
    this.$store.dispatch('auth/onAuth')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {String} ページ名
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/valiables.scss';

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

a:link {
  text-decoration: none;
}

#app {
  font-family: 'Open Sans', 'Roboto', 'Arial', 'San Fransisco', 'Segoe UI';
  background-color: white !important;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input:focus, select:focus {
  outline: 0;
  border: none;
}
</style>
