<template>
  <footer class="footer" v-if="displayFooter && active !== null">
    <div class="footer__menu" @click="move('Home')">
      <img class="footer__menu__img"
      :src="active.indexOf('Home') === 0 ? require('@/assets/img/home-orange.svg') : require('@/assets/img/home.svg')"  />
    </div>
    <div class="footer__menu" @click="move('Favorite')">
      <img class="footer__menu__img"
      :src="active.indexOf('Favorite') === 0 ? require('@/assets/img/heart-orange.svg') : require('@/assets/img/heart.svg')"  />
    </div>
    <div class="footer__menu" @click="move('Video')">
      <img class="footer__menu__img"
      :src="active.indexOf('Video') === 0 ? require('@/assets/img/video-orange.svg') : require('@/assets/img/video.svg')"  />
    </div>
    <div class="footer__menu" @click="move('Study')">
      <img class="footer__menu__img"
      :src="active.indexOf('Study') === 0 ? require('@/assets/img/study-orange.svg') : require('@/assets/img/study.svg')"  />
    </div>
    <div class="footer__menu" @click="move('Menu')">
      <img class="footer__menu__img"
      :src="active.indexOf('Menu') === 0 ? require('@/assets/img/menu-orange.svg') : require('@/assets/img/menu.svg')"  />
      <span v-if=hasUnread class="footer__menu__unread"></span>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
// フッターを表示しないページの名前
const NOT_DISPLAY_FOOTER_NAME = [
  'LP',
  'MenuCancelReason',
  'ProfileEdit',
  'Deleted',
  'Login',
  'Signup',
  'Exam',
  'ExamQuestion',
  'ExamResult',
  'Error',
  'Double',
  'NotFound'
]

export default {
  data () {
    return {
    }
  },
  computed: {
    // フッターを表示するかどうか
    displayFooter () {
      return !NOT_DISPLAY_FOOTER_NAME.includes(this.$route.name) && this.$route.name !== null
    },
    // 閲覧中のページ
    active () {
      return this.$route.name
    },
    // 未読があるかどうか
    hasUnread () {
      const unreadNoticeIDs = this.getUnreadNoticeIDs()()
      return unreadNoticeIDs.length > 0
    }
  },
  methods: {
    ...mapGetters('notice', ['getUnreadNoticeIDs']),
    /**
     * 指定ページに移動
     * @param {String} page ページ名（routerに記載のname）
     */
    move (page) {
      this.$router.push({ name: page })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

$img_size: 16px;

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  height: $footer_height;
  width: 100vw;
  background-color:rgba(255,255,255,0.9);
  &__menu {
    position: relative;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: $footer_height;
    text-align: center;
    &__img {
      width: $img_size;
      height: $img_size;
      margin-top: ($footer_height - $img_size) / 2;
      object-fit: contain;
      &--profile {
        @extend .footer__menu__img;
        border-radius: 50%;
      }
    }
    &__unread {
      position: absolute;
      top: 24px;
      left: 35px;
      display: block;
      height: 8px;
      width: 8px;
      margin-left: 7px;
      border-radius: 50%;
      background-color: #ff9534;
    }
  }
}
</style>
