import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 取得済み試験問題情報
  // { qid: {}, qid: {}, ... }
  questions: {},
  // 直前に回答した、回答順に並んだ試験問題のID
  answerOrderedQIDs: []
}

const getters = {
  /**
   * @param {String} eid 試験ID
   * @return {Object} 試験IDに紐づく試験問題情報一覧
   */
  questions: state => eid => {
    const questions = Object.keys(state.questions).reduce((questions, qid) => {
      if (state.questions[qid].eid === eid) questions[qid] = state.questions[qid]
      return questions
    }, {})
    return Object.keys(questions).length ? questions : null
  },
  /**
   * @return 直前に回答した、回答順に並んだ試験問題のID一覧
   */
  answerOrderedQIDs: state => state.answerOrderedQIDs
}

const actions = {
  /**
   * 削除済みでない試験問題情報一覧の取得
   * @param {String} eid 取得する問題の試験ID
   */
  getQuestions ({ commit, getters }, eid) {
    return new Promise(resolve => {
      firestore
        .collection('exams')
        .doc(eid)
        .collection('questions')
        .where('isDeleted', '==', false)
        .orderBy('updatedAt', 'asc')
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const question = { qid: doc.id, question: Object.assign(doc.data(), { eid: eid }) }
            commit('setQuestion', question)
          })
          return resolve(getters.questions(eid))
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
  /**
   * 試験問題情報をstateにセット
   * @param {String} payload.qid 試験問題ID
   * @param {Object} payload.question 試験問題情報
   */
  setQuestion: (state, payload) => {
    state.questions[payload.qid] = payload.question
  },
  /**
   * 試験問題情報をリセット
   */
  resetQuestions: (state) => {
    state.questions = {}
  },
  /**
   * 直前に回答した、回答順の試験問題のID配列をstateにセット
   * @param {String[]} qids 直前に回答した、回答順の試験問題のID配列
   */
  setAnswerOrderedQIDs: (state, qids) => {
    state.answerOrderedQIDs = qids
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
