// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App'
import vuetify from '@/plugins/vuetify'
import VueAnalytics from 'vue-analytics'
import store from '@/store'
import router from '@/router'
import '@/registerServiceWorker'

Vue.config.productionTip = false

/**
 * setting of vue analytics
 * @see https://github.com/MatteoGabriele/vue-analytics
 */
Vue.use(VueAnalytics, {
  id: 'UA-141685131-1',
  router
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  components: { App },
  template: '<App/>'
}).$mount('#app')
