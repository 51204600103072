import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

/**
 * setting of vuetify which is material design lib
 * @see https://vuetifyjs.com
 */
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md' || 'mdi' || 'fa4' || 'fa'
  }
})
