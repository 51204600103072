import { firestore } from '@/firebase'

const state = {
  // 最新のコンディション情報
  // 存在しない場合 null
  condition: {}

}

const getters = {
  /**
   * @return {Object} コンディション情報
   */
  getCondition: state => () =>
    state.condition
}

const actions = {
  /**
   * 最新のコンディションを取得
   * @param {String} uid ユーザーID
   */
  getNewCondition ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('condition')
        .where('uid', '==', uid)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(snapshot => {
          commit('setCondition', snapshot)
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * コンディションの作成
   * @param {Object} payload { uid: ユーザーID, condition: コンディション値（1 - 5）, createdAt: 作成日 }
   */
  createCondition ({ commit }, params) {
    return new Promise(resolve => {
      firestore.collection('condition').add(params).then(() => {
        commit('updateCondition', params)
        return resolve()
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  }
}

const mutations = {
  /**
   * 取得したコンディション情報を格納
   * @param {Object} snapshot 取得したドキュメント一覧（一つ or ゼロ）
   */
  setCondition: (state, snapshot) => {
    let condition = {}
    snapshot.forEach(doc => { condition = doc.data() })
    state.condition = Object.keys(condition).length > 0 ? condition : null
  },
  /**
   * 更新したコンディション情報の追加
   * @param {Object} params 更新したコンディションのパラメータ
   */
  updateCondition: (state, params) => {
    state.condition = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
