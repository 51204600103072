import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const actions = {
  /**
   * GPS情報の取得
   * @param uid GSP情報を取得するユーザーのID
   */
  getLocation ({ dispatch }, uid) {
    navigator.geolocation.getCurrentPosition(pos => {
      dispatch('addGPS', {
        uid: uid,
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      })
    })
  },
  /**
   * GPS情報の登録
   * @param {Object} payload { uid: ユーザーID, latitude: 緯度, longitude: 経度 }
   */
  addGPS ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('gps')
        .add({
          uid: payload.uid,
          latitude: payload.latitude,
          longitude: payload.longitude,
          createdAt: new Date()
        })
        .then(() => {
          return resolve()
        }).catch(() => {
          router.push({ name: 'Error' })
        })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
