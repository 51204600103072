import { firestore } from '@/firebase'

const state = {
  // 講師一覧
  // { id: {}, ... }
  teachers: {}
}

const getters = {
  /**
   * 指定IDの講師情報を取得
   * @param {String} id ドキュメントID
   * @return {Object} 講師情報
   */
  getTeacher: state => id =>
    state.teachers[id]
}

const actions = {
  /**
   * 全講師情報の取得
   */
  getTeachers ({ commit }) {
    return new Promise(resolve => {
      firestore.collection('teacher')
        .get()
        .then(snapshot => {
          commit('setTeachers', snapshot)
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

const mutations = {
  /**
   * 講師情報一覧をstateにセット
   * @param {Object} snapshot 取得結果
   */
  setTeachers: (state, snapshot) => {
    snapshot.forEach(doc => {
      state.teachers[doc.id] = doc.data()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
