import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import pin from '@/store/modules/pin'
import pinHistory from '@/store/modules/pin_history'
import vocabulary from '@/store/modules/vocabulary'
import exams from '@/store/modules/exams'
import questions from '@/store/modules/exams/questions'
import answers from '@/store/modules/exams/answers'
import results from '@/store/modules/exams/results'
import movie from '@/store/modules/movie'
import watchingMovie from '@/store/modules/watching_movie'
import occupation from '@/store/modules/occupation'
import topic from '@/store/modules/topic'
import favMovie from '@/store/modules/fav_movie'
import favVocabulary from '@/store/modules/fav_vocabulary'
import teacher from '@/store/modules/teacher'
import gps from '@/store/modules/gps'
import condition from '@/store/modules/condition'
import notice from '@/store/modules/notice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, user, pin, pinHistory, vocabulary, exams, questions, answers, results, movie, watchingMovie, occupation, topic, favMovie, favVocabulary, teacher, gps, condition, notice },
  strict: process.env.NODE_ENV !== 'production',
  state: {
    isAuthProcessing: true,
    redirectPath: null,
    telop: {
      show: false,
      msg: '',
      type: ''
    }
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    }
  }
})
