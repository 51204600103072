/**
 * file for writing to common functions
 * [usage]
 * import CommonFunction from '@/lib/common'
 * extends: CommonFunction
 */
import Vue from 'vue'
import moment from 'moment'
import router from '@/router'
import Fingerprint2 from 'fingerprintjs2'
import xxhash from 'xxhash-wasm'

// ページ下部の定義 下部からBOTTOM_PERCENTAGE[%]を下部とする
const BOTTOM_PERCENTAGE = 0.4

/* eslint-disable no-new */
export default Vue.extend({
  methods: {
    /**
     * @example arrary.sort(sortObjectKey(key, 'desc'))
     * @param {String} key ソート対象の要素
     * @param {String} order ソート順（降順の場合はdescを指定）
     * @return {Arrayr} ソート結果を返却
     */
    sortObjectKey (key, order = 'asc') {
      return (a, b) => {
        if (!Object.hasOwnProperty.call(a, key) || !Object.hasOwnProperty.call(b, key)) {
          return 0
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    /**
     * @param {Date} date フォーマット変換したい日付
     * @param {String} style フォーマット後のスタイル YYYY-MM-DDなど
     * @return {String} フォーマット後の日付
     */
    formated (date, style = 'YYYY/MM/DD') {
      return moment(date).format(style)
    },
    /**
     * @return {Boolean} ページ下部かどうか
     */
    isBottom () {
      const body = window.document.body
      const html = window.document.documentElement
      const scrollTop = body.scrollTop || html.scrollTop
      return (
        1 - scrollTop / (html.scrollHeight - html.clientHeight) <
       BOTTOM_PERCENTAGE
      )
    },
    /**
     * フィンガープリントの取得
     * @param {Object} excludes 除外オプション
     * @return {String} フィンガープリント
     */
    async getDeviceID (excludes) {
      const deviceID = await getDeviceID(excludes)
      return deviceID
    }
  }
})

// store/modulesのjsファイルからも呼び出せるように処理を外出し
/**
 * フィンガープリントの取得
 * @param {Object} excludes 除外オプション
 * @return {String} フィンガープリント
 */
const getDeviceID = (excludes) => {
  return new Promise(resolve => {
    const getHash = () => {
      let hash = 'none'
      const options = { excludes: excludes }
      try {
        Fingerprint2.get(options, components => {
          let input = ''
          components.forEach(component => {
            // userAgentの場合、OSのバージョンアップでハッシュが変更されないよう機種以外の情報を削除
            if (component.key === 'userAgent') {
              const regexp = /\(.*?;/
              input += regexp.exec(component.value)[0]
            } else {
              input += component.value
            }
          })
          // xxhashでdeviceIDをハッシュ化する
          xxhash().then(hasher => {
            hash = hasher.h64(input)
            return resolve(hash)
          })
        })
      } catch (e) {
        // Fingerprint2.getでcomponentが取得できなかった場合エラーに飛ばす
        router.push({ name: 'Error' })
      }
    }
    // Fingerprintjs2は少し時間を空けて起動する必要があるのでrequestIdleCallbackでディレイをかける
    if (window.requestIdleCallback) {
      requestIdleCallback(getHash)
    } else {
      // requestIdleCallback未対応ブラウザの場合はsetTimeoutを使用する
      setTimeout(getHash, 500)
    }
  })
}

export { getDeviceID }
