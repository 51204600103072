<template>
  <header v-if="isDisplay" :style="{ backgroundColor: backgroundColor }">
    <div class="header--noback" v-if="this.type.includes('title') && !this.isSearchResult">
      <h1 class="header__title--noback">{{ title }}</h1>
      <img v-if="type.includes('search') && pageName !== 'Search'"
      class="header__search" src="@/assets/img/search.svg" @click="$router.push({ name: 'Search' })" />
      <img v-if="type.includes('search') && pageName === 'Search'"
      class="header__search" src="@/assets/img/close.svg" @click="$router.push({ name: 'Video' })" />
    </div>
    <div class="header--menu" v-else-if="type.includes('menu')">
      <h1 :class="color === 'orange' ? 'header__title--white' : 'header__title--menu'">{{ title }}</h1>
      <button class="header__back--menu" @click="type.includes('exam') ? $router.push({ name: 'Study' }) : $router.go(-1)">
        <img :src="color === 'orange' ? require('@/assets/img/arrow_foward-white.svg') : require('@/assets/img/arrow_foward-gray.svg')" />
      </button>
    </div>
    <div class="header--back" v-else>
      <button class="header__back" @click="$router.go(-1)">
        <img :src="color === 'orange' ? require('@/assets/img/arrow_foward-white.svg') : require('@/assets/img/arrow_foward-gray.svg')" />
      </button>
      <h2 v-if="title.length > 0" class="header__title--small">{{ title }}</h2>
    </div>
  </header>
</template>

<script>
import scssColor from '@/assets/sass/color.scss'

// ヘッダーを表示しないページの名前
const NOT_DISPLAY = [
  'LP',
  'VideoDetail',
  'Deleted',
  'Login',
  'Signup', // 独自ヘッダー
  'ExamQuestion', // 独自ヘッダー
  'Error',
  'Double',
  'NotFound'
]

// ヘッダーの内容
const HEADER_DETAIL = {
  Home: {
    type: ['title'],
    title: 'Trang chủ'
  },
  Favorite: {
    type: ['title'],
    title: 'Ưa thích'
  },
  Video: {
    type: ['title', 'search'],
    title: 'Video'
  },
  Study: {
    type: ['title'],
    title: 'Luyện tập'
  },
  Search: {
    type: ['title', 'search', 'back'],
    title: 'Tìm kiếm',
    resultTitle: 'Kết quả'
  },
  StudyVocabulary: {
    type: ['back'],
    title: '',
    color: 'orange'
  },
  StudyVocabularyTest: {
    type: ['back'],
    title: ''
  },
  Menu: {
    type: ['title'],
    title: 'Danh mục'
  },
  MenuNotification: {
    type: ['back', 'menu'],
    title: 'Thông báo',
    color: 'gray'
  },
  MenuCancel: {
    type: ['back', 'menu'],
    title: 'Hủy bỏ thành viên',
    color: 'gray'
  },
  MenuCancelReason: {
    type: ['back', 'menu'],
    title: 'Hủy bỏ thành viên',
    color: 'gray'
  },
  ProfileEdit: {
    type: ['back', 'menu'],
    title: 'Chỉnh sửa thông tin cá nhân',
    color: 'gray'
  },
  Exam: {
    type: ['back', 'menu', 'exam'],
    title: 'KIỂM TRA KĨ NĂNG',
    color: 'orange'
  },
  ExamResult: {
    type: ['back', 'menu', 'exam'],
    title: 'Kết quả',
    color: 'gray'
  }
}

// 背景色の名前とカラーコード
const BACKGROUND_COLOR = {
  orange: scssColor.orange_color,
  gray: scssColor.gray_color
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示するかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.pageName)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {Boolean} 検索結果画面かどうか
     */
    isSearchResult () {
      return this.pageName === 'Search' && this.hasTags
    },
    /**
     * @return {Boolean} 検索タグを持っているかどうか
     */
    hasTags () {
      const hasLevels = this.$route.query.levels !== undefined
      const hasTopics = this.$route.query.topics !== undefined
      const hasOccupations = this.$route.query.occupations !== undefined
      return hasLevels || hasTopics || hasOccupations
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      if (this.pageName === 'StudyVocabularyTest') {
        return this.$route.query.level + '-' +
            (this.$route.query.genre === '名詞' ? '1'
              : this.$route.query.genre === '動詞' ? '2'
                : '3')
      } else if (this.isSearchResult) {
        return HEADER_DETAIL[this.pageName].resultTitle
      } else {
        /**
         * @see https://github.com/nukumo/hanabi-main/issues/12
         * @todo 以上課題対応後、以下コメントアウトした記載で
         * 初回アクセス（画面更新）時にコンソールエラーが出ないことを確認
         */
        // return HEADER_DETAIL[this.pageName].title
        return HEADER_DETAIL[this.pageName] ? HEADER_DETAIL[this.pageName].title : ''
      }
    },
    /**
     * @return {String[]} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : []
    },
    /**
     * @return {String} ヘッダーの背景色名
     */
    color () {
      return this.pageName && HEADER_DETAIL[this.pageName].color ? HEADER_DETAIL[this.pageName].color : ''
    },
    /**
     * @return {String} 背景色のカラーコード
     */
    backgroundColor () {
      return this.color ? BACKGROUND_COLOR[this.color] : scssColor.white_color
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.header {
  width: 100vw;
  z-index: 10;
  box-sizing: border-box;
  &--noback {
    @extend .header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $header_padding_top $main_padding $header_padding_bottom $main_padding;
  }
  &--back {
    @extend .header;
    padding: $header_padding_top $main_padding $header_padding_bottom $main_padding;
  }
  &--menu {
    @extend .header;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $header_height;
  }
  &__title {
    margin-top: 11px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: $black_color;
    &--noback {
      @extend .header__title;
      font-size: 35px;
      line-height: 35px;
      color: $orange_color;
      text-align: left;
    }
    &--menu {
      @extend .header__title;
      margin-top: 0;
      padding: 0 50px;
      font-weight: normal;
    }
    &--white {
      @extend .header__title;
      margin-top: 0;
      padding: 0 50px;
      font-weight: normal;
      color: $white_color;
    }
    &--small {
      @extend .header__title;
      width: 100%;
    }
  }
  &__search {
    height: 23.85px;
    width: 23.85px;
  }
  &__back {
    height: 20px;
    width: 20px;
    &--menu {
      @extend .header__back;
      position: absolute;
      top: 0;
      left: $main_padding;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>
